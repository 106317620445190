import React from "react";
import { Cta } from "../components/Cta";
import FaqSection from "../components/FaqSection";
import Layouts from "../components/Layouts";
import Seo from "../components/Seo";
import { ContactSection } from "../components/Screens/Contact/ContactSection";
import { graphql } from "gatsby";

const ContactPage = ({ data }) => {
	const page = data.page;
	return (
		<>
			<Seo title="Contact Us" />
			<Layouts lang={"en"}>
				<ContactSection
					header={
						<div className="sm:w-9/12 xl:w-7/12">
							{page.contact.contactInfo.title}
						</div>
					}
					text={page.contact.contactInfo.text}
					lang="en"
				/>
				<FaqSection
					header={page.contact.frequentlyAskedQuestions.title}
					subHeader={page.contact.frequentlyAskedQuestions.text}
					items={page.contact.frequentlyAskedQuestions.questions}
				/>
				<Cta
					miniHeader="EGYPT's First Nano Financial Service Provider"
					header="Powering Access to Digital Financial Possibilities"
					subHeader="Kashat serves as the entry point to financial inclusion for the un- and underbanked population in Egypt by offering instant, small, short-term, productive loans."
				/>
			</Layouts>
		</>
	);
};
export default ContactPage;
export const ContactEnglishQuery = graphql`
	query ContactEnglish {
		page: wpPage(databaseId: { eq: 381 }) {
			title
			contact {
				contactInfo {
					title
					text
					phone
					email
				}
				frequentlyAskedQuestions {
					title
					text
					questions {
						question
						answer
					}
				}
			}
		}
	}
`;
